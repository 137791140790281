import React, { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import { ApiService } from "../../Components/Services/apiservices";
import { validNumber } from "../../Components/Elements/Regex";
import Alert from "react-bootstrap/Alert";

function EnquiryModal({
    showmodal,
    onChildData
}) {


  const didMountRef = useRef(true);

    const [show, setShow] = useState(showmodal);

const handleClose = () =>{
    setShow(false);
    onChildData(false);
}
const [errorMessage, setErrorMessage] = useState("");
const [successMessage, setSuccessMessage] = useState("");
const [enquiryDetails, setEnquiryDetails] = useState({
    enquiry_name: "",
    enquiry_mobile:"",
    enquiry_message: "",   
  });
useEffect(() => {
    if (didMountRef.current) {

    }
    didMountRef.current = false;
}, []);


const enquiryProcess = () => {
    let counter = 0;
    const myElements = document.getElementsByClassName("required");
    for (let i = 0; i < myElements.length; i++) {
      if (myElements[i].value === "") {
        myElements[i].style.border = "1px solid red";
        counter++;
      } else {
        myElements[i].style.border = "";
      }
    }
    if (counter === 0) {
      setErrorMessage("");
      if (!validNumber.test(enquiryDetails.enquiry_mobile)) {
        setErrorMessage("Please enter valid Mobile Number");
        return false;
      }

      ApiService.postData("enquiry-process", enquiryDetails).then((res) => {
        if (res.status === "success") {
          setSuccessMessage(res.message);
        //   resetThankYouForm();
         
          setTimeout(() => {
            setSuccessMessage("");
            window.location.reload()
          }, 4000);
       
        } else {
          setErrorMessage(res.message);
          setTimeout(() => {
            setErrorMessage("");
          }, 3000);
        }
      });
    }
}


  const onTodoChange = (e) => {
    const { name, value } = e.target;
    setEnquiryDetails((prevState) => ({
      ...prevState,
   
      [name]: value,
    }));
  };





  return (
    <>
    <Modal show={show} onHide={handleClose} className="">
    <button onClick={handleClose} className="pop-close "></button> 

    <Modal.Body>
    <Modal.Title className="tx-theme">Enquiry Form</Modal.Title>

      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
              {successMessage && (
                <Alert variant="success">{successMessage}</Alert>
              )}
      <div className="row g-3">
      <div className="col-lg-12">
      <div className="form-group mb-3">
      <label className='mb-1'>Full Name</label>
              <input
                type="text"
                name="enquiry_name"
                className="form-control"
                value={enquiryDetails.enquiry_name}
                onChange={(e) => onTodoChange(e)}
              
              />
            </div>
        
                </div>
            <div className="col-lg-12">
            <div className="form-group mb-3">
            <label className='mb-1'>Mobile Number</label>
              <input
                type="number"
                name="enquiry_mobile"
                className="form-control "
                value={enquiryDetails.enquiry_mobile}
                onChange={(e) => onTodoChange(e)}
        
              />
            </div>  
            </div>
            {/* <div className="col-lg-12">
            <div className="form-group mb-3">
            <label className='mb-1'>Email</label>
              <input
                type="text"
                name="enquiry_email"
                className="form-control "
                value={enquiryDetails.enquiry_email}
                onChange={(e) => onTodoChange(e)}
        
              />
            </div>  
            </div> */}
            <div className="form-group-modal mb-3">
                    <label className='mb-1'>Message</label>
                    <textarea
                      name="enquiry_message"
                      className="form-control "
                      value={enquiryDetails.enquiry_message}
                      onChange={(e) => onTodoChange(e)}
                    />
                  </div>
            
            <div className="d-grid mb-4">
              <Button className="btn btn-primary-outline btn-medium me-3" onClick={enquiryProcess}>Submit</Button>
            </div>
            </div>
      </Modal.Body>
 
 
    </Modal>
    </>
  )
}

export default EnquiryModal