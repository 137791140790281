// import React, { useEffect, useState, useRef } from 'react';
// import { ColorRing } from  'react-loader-spinner'
// function SpinnerLoader() {
//   return (
//     <>
//        <>
//       <div className='loadergif' width="100%">
//       <img
//               src="/img/loader.gif" 
//               alt="Loading..."
          
//               width="100"
//             />
//         </div>
//     </>
     
//     </>
//   );
// }
// export default SpinnerLoader;


import React, { useEffect, useState, useRef } from 'react';
import { InfinitySpin } from  'react-loader-spinner'
function SpinnerLoader() {
  return (
    <>
      <div className='parentDisable' width="100%">
          <div className='overlay-box'>
          <InfinitySpin 
  width='200'
  color="#b1822f"
/>
          </div>
        </div>
    </>
  );
}
export default SpinnerLoader;

